<template>
  <section
    class="conOfProducts pt-5 h-100"
    v-if="productsList && productsList.length > 0"
  >
    <v-container>
      <v-row>
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="product in productsList"
          :key="product.id"
        >
          <router-link
            :to="{
              name: 'settingProductItem',
              params: { id: product.id },
            }"
          >
            <v-card
              class="mx-auto pa-5 d-flex align-center justify-center"
              max-width="100%"
              min-height="170"
              elevation="1"
            >
              <div class="text-center">
                <img
                  :src="require(`@/assets/img/productsLogos/${product.id}.png`)"
                  alt="productItem"
                />
              </div>
            </v-card>
          </router-link>
        </v-col>
        <v-col lg="3" md="4" sm="6" cols="12">
          <router-link
            :to="{
              name: 'settingAllProducts',
            }"
          >
            <v-card
              class="
                mx-auto
                pa-5
                conAllProducts
                d-flex
                justify-center
                align-center
              "
              max-width="100%"
              min-height="170"
              elevation="1"
            >
              <div class="text-center allProducts">All Products</div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <div class="loaderContainer" v-else-if="isLoading">
    <v-progress-circular
      :size="120"
      :width="4"
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>
  <EmptyState v-else></EmptyState>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";

export default {
  data: () => ({
    isLoading: false,
    productsList: null,
  }),
  components: {
    EmptyState,
  },
  methods: {
    initialize() {
      this.productsList = [];
      this.isLoading = true;

      apiServices.get("admin/products").then((res) => {
        if (res) {
          this.isLoading = false;
          this.productsList = res.data;
          console.log(res.data);
        }
      });
    },
  },
  created() {
    this.initialize();
  },
};
</script>
<style lang="scss" scoped>
@import "./_Products.scss";
</style>
